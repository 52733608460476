import React, { FC, useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  MapPinIcon,
  FlagIcon,
  ArrowsRightLeftIcon,
  UserIcon,
  AtSymbolIcon,
  ChatBubbleLeftRightIcon,
  IdentificationIcon,
} from "@heroicons/react/24/solid";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import { useNavigate } from "react-router-dom";

const libraries: ("places")[] = ["places"];

const AirportTransferPage: FC = () => {
  const [pickupAddress, setPickupAddress] = useState("");
  const [dropoffAddress, setDropoffAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [roundTrip, setRoundTrip] = useState(false);
  const [returnDate, setReturnDate] = useState("");
  const [returnTime, setReturnTime] = useState("");
  const [adults, setAdults] = useState(1);
  const [childSeats, setChildSeats] = useState("");
  const [nameSign, setNameSign] = useState("");
  const [comment, setComment] = useState("");
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [distance, setDistance] = useState<string | null>(null); // State for distance

  const navigate = useNavigate();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  const pickupRef = useRef<google.maps.places.SearchBox | null>(null);
  const dropoffRef = useRef<google.maps.places.SearchBox | null>(null);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("airportTransferData");
    if (storedData) {
      const formData = JSON.parse(storedData);
      setPickupAddress(formData.pickupAddress || "");
      setDropoffAddress(formData.dropoffAddress || "");
      setDate(formData.date || "");
      setTime(formData.time || "");
      setRoundTrip(formData.roundTrip || false);
      setReturnDate(formData.returnDate || "");
      setReturnTime(formData.returnTime || "");
      setAdults(formData.adults || 1);
      setChildSeats(formData.childSeats || "");
      setNameSign(formData.nameSign || "");
      setComment(formData.comment || "");
      setEmailOrPhone(formData.emailOrPhone || "");
      setTermsAccepted(formData.termsAccepted || false);
      setDistance(formData.distance || null);
    }
  }, []);

  const fetchDistance = async () => {
    if (pickupAddress && dropoffAddress) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_LINK}/airport-transfer/calculate-distance?origins=${encodeURIComponent(
            pickupAddress
          )}&destinations=${encodeURIComponent(dropoffAddress)}`
        );

        const data = await response.json();
        if (data.distance) {
          setDistance(data.distance);
          return data.distance; // Mengembalikan nilai distance
        } else {
          setDistance("Distance not available");
        }
      } catch (error) {
        setDistance("Error calculating distance");
      }
    }
    return null;
  };

  const isFormValid = () => {
    return (
      pickupAddress &&
      dropoffAddress &&
      date &&
      time &&
      adults > 0 &&
      termsAccepted
    );
  };

  const handleSearch = async () => {
    if (!isFormValid()) {
      alert("Please fill in all required fields.");
      return;
    }
  
    const calculatedDistance = await fetchDistance(); // Pastikan ini selesai sebelum melanjutkan
  
    if (!calculatedDistance) {
      console.error("Distance was not calculated correctly, aborting search...");
      return;
    }
  
    const formData = {
      pickupAddress,
      dropoffAddress,
      date,
      time,
      roundTrip,
      returnDate,
      returnTime,
      adults,
      childSeats,
      nameSign,
      comment,
      emailOrPhone,
      termsAccepted,
      distance: calculatedDistance, // Pastikan menggunakan calculatedDistance
      seat: "None", // Contoh seat, bisa diubah sesuai logika
      note: comment || "No special requests", // Menambahkan note
    };
  
    localStorage.setItem("airportTransferData", JSON.stringify(formData));
  
    const searchParams = new URLSearchParams({
      pickupAddress,
      dropoffAddress,
      date,
      time,
      roundTrip: roundTrip.toString(),
      adults: adults.toString(),
      childSeats,
      nameSign,
      comment,
      emailOrPhone,
      termsAccepted: termsAccepted.toString(),
      distance: calculatedDistance || "", // Sertakan distance dalam URL parameters
      seat: "Standard", // Sertakan seat dalam URL parameter
      note: comment || "No special requests", // Sertakan note dalam URL parameter
    });
  
    navigate(`/airport-transfer/search?${searchParams.toString()}`);
  };
  

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const handleSwapLocations = () => {
    const tempAddress = pickupAddress;
    setPickupAddress(dropoffAddress);
    setDropoffAddress(tempAddress);
  };

  return (
    <div className="nc-AirportTransferPage overflow-hidden relative">
      <Helmet>
        <title>Book Bali Airport Transfer Cheap and Local Balinese Company</title>
        <meta name="description" content="Book your bali airport transfer easily that cheaper than any online apps and our coverage to Ubud, Kuta, Seminyak, Lovina or Uluwatu. Search your hotel and then we give quotes and you book with cash or credit card payment." />
        <link rel="canonical" href="https://baliguided.com/airport-transfer" />
      </Helmet>

      <BgGlassmorphism />

      

      {/* Pemberitahuan di atas card */}
      <div className="container mx-auto mb-4 mt-5 relative z-10">
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
          <p className="font-bold">Looking for a hotel transfer?</p>
          <p>
            <Link to="/hotel-transfer" className="underline">
              Book Hotel Transfer here
            </Link>
          </p>
        </div>
      </div>

      <div className="container mx-auto p-8 relative">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Book your airport transfer in Bali</h2>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <MapPinIcon className="h-6 w-6 text-gray-500" />
              <span className="text-gray-700">Airport Pickup (Search Denpasar Airport (Bandara International DPS))</span>
            </div>
            <div className="w-full flex flex-col space-x-2">
              <StandaloneSearchBox
                onLoad={(ref) => (pickupRef.current = ref)}
                onPlacesChanged={() => {
                  const places = pickupRef.current?.getPlaces();
                  if (places && places.length > 0 && places[0].name) {
                    setPickupAddress(places[0].name);
                  }
                }}
              >
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Type the address here & choose from the list"
                    className="border p-2 rounded w-full"
                    style={{ boxSizing: "border-box" }}
                    value={pickupAddress}
                    onChange={(e) => setPickupAddress(e.target.value)}
                  />
                </div>
              </StandaloneSearchBox>
            </div>
            <div className="flex items-center space-x-2">
              <FlagIcon className="h-6 w-6 text-gray-500" />
              <span className="text-gray-700">Destination (Hotel, Port or Tourist Destination)</span>
            </div>
            <div className="w-full flex flex-col space-x-2">
              <StandaloneSearchBox
                onLoad={(ref) => (dropoffRef.current = ref)}
                onPlacesChanged={() => {
                  const places = dropoffRef.current?.getPlaces();
                  if (places && places.length > 0 && places[0].name) {
                    setDropoffAddress(places[0].name);
                  }
                }}
              >
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Type the address here & choose from the list"
                    className="border p-2 rounded w-full"
                    style={{ boxSizing: "border-box" }}
                    value={dropoffAddress}
                    onChange={(e) => setDropoffAddress(e.target.value)}
                  />
                </div>
              </StandaloneSearchBox>
              <button
                onClick={handleSwapLocations}
                className="mt-5 ml-2 p-2 bg-gray-200 rounded flex items-center justify-center"
                type="button"
              >
                <ArrowsRightLeftIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col w-full">
                <label className="text-xs text-neutral-500 mb-1">Select Date</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="text-xs text-neutral-500 mb-1">Select Time</label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="border p-2 rounded w-full"
                />
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={roundTrip}
                onChange={(e) => setRoundTrip(e.target.checked)}
                className="h-6 w-6"
              />
              <span>Round trip</span>
            </div>
            {roundTrip && (
              <>
                <span className="block mt-2 text-gray-700">
                  Choose when you will check out back to Hotel (Usually 4 Hour before Boarding Time)
                </span>
                <div className="flex space-x-4 mt-4">
                  <input
                    type="date"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                    className="border p-2 rounded w-full"
                  />
                  <input
                    type="time"
                    value={returnTime}
                    onChange={(e) => setReturnTime(e.target.value)}
                    className="border p-2 rounded w-full"
                  />
                </div>
              </>
            )}
            <div className="flex items-center space-x-2">
              <UserIcon className="h-6 w-6 text-gray-500" />
              <button
                onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}
                className="p-2 bg-gray-200 rounded"
              >
                -
              </button>
              <span>{adults}</span>
              <button
                onClick={() => setAdults(adults + 1)}
                className="p-2 bg-gray-200 rounded"
              >
                +
              </button>
              <span>Persons</span>
            </div>
            <div className="flex items-center space-x-2">
              <select
                value={childSeats}
                onChange={(e) => setChildSeats(e.target.value)}
                className="border p-2 rounded w-full"
              >
                <option value="">Child seats</option>
                <option value="none">None</option>
                <option value="1">1 seat</option>
                <option value="2">2 seats</option>
                <option value="3">3 seats</option>
              </select>
            </div>
            {/* <div className="flex items-center space-x-2">
              <IdentificationIcon className="h-6 w-6 text-gray-500" />
              <input
                type="text"
                placeholder="Name on a sign the driver'll hold"
                value={nameSign}
                onChange={(e) => setNameSign(e.target.value)}
                className="border p-2 rounded w-full"
              />
            </div> */}
            <div className="flex items-center space-x-2">
              <ChatBubbleLeftRightIcon className="h-6 w-6 text-gray-500" />
              <input
                type="text"
                placeholder="Luggage information, special needs or tasks for the driver"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="border p-2 rounded w-full"
              />
            </div>
            {/* <div className="flex items-center space-x-2">
              <AtSymbolIcon className="h-6 w-6 text-gray-500" />
              <input
                type="text"
                placeholder="Email or phone"
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)}
                className="border p-2 rounded w-full"
              />
            </div> */}
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="h-6 w-6"
              />
            <span>
              I accept the terms of <Link to="/terms-condition" className="text-blue-500 underline">BaliGuided Airport Transfer Service Agreement</Link>
            </span>
            </div>
            <button
              onClick={handleSearch}
              className={`${
                isFormValid() ? "bg-yellow-500" : "bg-gray-300 cursor-not-allowed"
              } text-white py-2 px-4 rounded w-full`}
              disabled={!isFormValid()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirportTransferPage;
