import httpClient from '../utils/httpClient';


const validateTransaction = async (orderID) => {
  try {
    const response = await httpClient.post('/payment/paypal', { orderID });
    return response.data;
  } catch (error) {
    console.error("Error validating transaction:", error);
    throw error;
  }
};

export default {
  validateTransaction,
};
