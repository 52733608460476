import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import paymentService from "../../services/paymentService";


interface PayPalButtonProps {
  amount: string;
  onSuccess: (details: any) => void;
}

function PayPalButton({ amount, onSuccess }: PayPalButtonProps) {
  return (
    <PayPalScriptProvider
      options={{
        clientId: "AS2XXl6VfUPvIkkPIW8Byk46zxlN4tifEy5mo1_HxUPznw0i1b3HgSAFriCBRgpCfmvLynUH6RYtcGep",
        currency: "USD", // Pastikan currency IDR untuk Rupiah
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  currency_code: "USD", // Pastikan currency code IDR
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const { orderID } = data;
          console.log("Order ID received:", orderID);
        
          try {
            // Call the service to validate the transaction
            const response = await paymentService.validateTransaction(orderID);
            console.log("Validation response:", response);
        
            if (response.status === "success") {
              console.log("Transaction validated successfully:", response);
              onSuccess(response); // Proceed with success logic
            } else {
              console.warn("Transaction validation failed:", response);
            }
          } catch (error) {
            // Handle errors in validation service call
            if (axios.isAxiosError(error)) {
              if (error.response) {
                console.error("Server responded with status:", error.response.status, "Message:", error.response.data);
              } else if (error.request) {
                console.error("No response from server. Request details:", error.request);
              } else {
                console.error("Unexpected error during request setup:", error.message);
              }
            } else {
              console.error("An unknown error occurred:", error);
            }
          }
        }}        
        onError={(err) => {
          console.error("Error with PayPal:", err);
        }}        
      />
    </PayPalScriptProvider>
  );
}

export default PayPalButton;
