import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page as PageType } from "./types"; // Menggunakan alias untuk menghindari konflik
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperienceCategoryPage from "containers/ListingExperiencesPage/ListingExperienceCategoryPage";
import ListingExperiencesPageSearch from "containers/ListingExperiencesPageSearch/ListingExperiencesPageSearch";
import ListingExperiencesPageSearchCategories from "containers/ListingExperiencesPageSearch/ListingExperiencesPageSearch";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import VerifEmailMessage from "containers/PageSignUp/VerifEmailMessage";
import PageLogin from "containers/PageLogin/PageLogin";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNavMobile/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import AdminPage from "containers/AdminPage/AdminPage";
import AdminDashboard from "containers/AdminDashboard/AdminDashboard";
import VerifEmailSuccess from "containers/PageSignUp/VerifEmailSuccess";
import ForgotPassword from "containers/PageLogin/ForgotPassword";
import SolveForgotPassword from "containers/PageLogin/SolveForgotPassword";
import OauthSuccess from "containers/OauthPage/Success";
import OauthError from "containers/OauthPage/Error";
import AirportTransfer from "containers/AirportTransfer/AirportTransfer"; 
import AirportTransferCheckout from "containers/AirportTransferCheckout/AirportTransferCheckout";
import AirportTransferSearch from "containers/AirportTransferSearch/AirportTransferSearch";
import AirportTransferPayDone from "containers/AirportTransferPayDone/AirportTransferPayDone";
import HotelTransfer from "containers/HotelTransfer/HotelTransfer";
import TermsCondition from "containers/TermsCondition/TermsCondition";
import HotelTransferSearch from "containers/HotelTransferSearch/HotelTransferSearch";
import HotelTransferCheckout from "containers/HotelTransferCheckout/HotelTransferCheckout";
import HotelTransferPayDone from "containers/HotelTransferPayDone/HotelTransferPayDone";


// Pastikan tipe PageType memiliki path sebagai string
export interface Page {
  path: string;
  component: React.ComponentType;
}

export const pages: PageType[] = [
  { path: "/", component: PageHome },
  { path: "/accomodation", component: ListingStayPage },
  { path: "/experiences", exact: true, component: ListingExperiencesPage },
  { path: "/experiences/search", exact: true, component: ListingExperiencesPageSearch },
  { path: "/experiences/details/:slug", component: ListingExperiencesDetailPage }, // Rute detail dengan slug
  { path: "/activities/:category", component: ListingExperienceCategoryPage}, // Rute detail dengan slug
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/account", component: AccountPage },
  { path: "/security", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/blog", component: BlogPage },
  { path: "/blog/:slug", component: BlogSingle }, // Perbaiki route dengan slug
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/verif-email", component: VerifEmailMessage },
  { path: "/verify-email/success", component: VerifEmailSuccess },
  { path: "/forgot-pass", component: ForgotPassword },
  { path: "/forgot-password/solve", component: SolveForgotPassword },
  { path: "/admin", component: AdminPage },
  { path: "/admindashboard", component: AdminDashboard },
  { path: "/oauth/success", component: OauthSuccess },
  { path: "/oauth/error", component: OauthError },
  { path: "/airport-transfer", component: AirportTransfer },
  { path: "/airport-transfer/checkout", component: AirportTransferCheckout },
  { path: "/airport-transfer/search", component: AirportTransferSearch },
  { path: "/airport-transfer/pay-done", component: AirportTransferPayDone },
  { path: "/hotel-transfer", component: HotelTransfer },
  { path: "/hotel-transfer/search", component: HotelTransferSearch },
  { path: "/hotel-transfer/checkout", component: HotelTransferCheckout }, 
  { path: "/hotel-transfer/pay-done", component: HotelTransferPayDone }, 
  { path: "/terms-condition", component: TermsCondition },
  { path: "/experiences/categories/:categoryName", component: ListingExperiencesPageSearchCategories },

];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component: any = component;
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
